import {BookingJob, BookingJobState} from '../services/bookingJob';
import * as React from 'react';
import {Translation} from '@autopay.io/translation';
import {Button, IconWithLabel, ProgressBar, Spacer} from '@autopay.io/style';
import './bookingJobInformation.less';

interface BookingJobProgressInformationProps {
    jobState: BookingJobState;
    removeJob: () => void;
    cancelJob: () => void;
}

export const BookingJobProgressInformation = (props: BookingJobProgressInformationProps) => {
    const [showAllErroredItems, setShowAllErroredItems] = React.useState(false);

    const titleText = (job: BookingJob) => {
        const status = job.status;

        if (status === 'IN_PROGRESS') {
            return Translation.messages().bookingClient.message.ongoing_job;
        } else if (status === 'SUCCESS') {
            return Translation.messages().bookingClient.message.job_finished;
        } else {
            return Translation.messages().bookingClient.message.job_cancelled;
        }
    };

    const successText = (job: BookingJob) => {
        return Translation.tokenize(Translation.messages().bookingClient.message.bookings_issued_successfully).setValue('amount', job.completed.toString()).buildString();
    };

    const failedText = (job: BookingJob) => {
        const amount = job.cancelled + job.errorItems.length;

        return Translation.tokenize(Translation.messages().bookingClient.message.bookings_creation_failed).setValue('amount', amount.toString()).buildString();
    };

    const issueText = (job: BookingJob) => {
        return Translation.tokenize(Translation.messages().bookingClient.message.issuing_multiple_bookings)
            .setValue('amount', job.total.toString())
            .buildString();
    };

    if (props.jobState.type === 'DATA' && props.jobState.data.status === 'IN_PROGRESS') {
        const job = props.jobState.data;
        return (
            <>
                <div className="permit-job-progress-container">
                    <div className="align-h-and-button">
                        <h3>{titleText(props.jobState.data)}</h3>
                        <Button size="sm" type="submit" onClick={props.cancelJob}>{Translation.messages().common.buttons.cancel}</Button>
                    </div>
                    <Spacer size="xxs" />
                    {issueText(props.jobState.data)}
                    <Spacer size="xxs" />
                    <ProgressBar completionPercent={job.progress} transitionPeriod={0.5} />
                </div>
                <Spacer size="sm" />
            </>
        );
    } else if (props.jobState.type === 'DATA') {
        return (
            <>
                <div className="permit-job-progress-container">
                    <div className="align-h-and-button">
                        <h3>{titleText(props.jobState.data)}</h3>
                        <Button size="sm" type="submit" onClick={props.removeJob}>{Translation.messages().common.buttons.remove}</Button>
                    </div>
                    <Spacer size="xxs" />
                    <IconWithLabel label={successText(props.jobState.data)!!} icon="check_green" />
                    {props.jobState.data.errorItems.length > 0 &&
                        (
                            <div>
                                <Spacer size="xxs" />
                                <IconWithLabel label={failedText(props.jobState.data)} icon="cross_red" />
                                <ul style={{listStyleType: 'none'}}>
                                    {props.jobState.data.errorItems.slice(0, showAllErroredItems ? props.jobState.data.errorItems.length - 1 : 4)
                                        .map((email, index) => <li key={index}>{email}</li>)}
                                </ul>
                                {props.jobState.data.errorItems.length > 5 &&
                                    (
                                        <p className="batch-permit-text-button" onClick={() => setShowAllErroredItems((old) => !old)}>
                                            {Translation.messages().bookingClient.message.see_all}
                                        </p>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <Spacer size="sm" />
            </>
        );
    } else {
        return null;
    }
};
