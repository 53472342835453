import * as React from 'react';
import { Button, ButtonContainer, FormGroup, IconWithTooltip, Label, Popup, Spacer, Textarea, useToasts } from '@autopay.io/style';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { FormikButton, FormikErrorMessage, FormikForm, FormikInput } from '@autopay.io/style/lib/formik';
import {BookingAvailabilityState, CreateOrEditBookingForm, BookingPayload, Permit, MultipleBookingPayload, SingleBookingPayload, BookingOverlap} from '../types';
import { HttpResponse } from '../../../types/http';
import Availability from './Availability';
import { Translation } from '@autopay.io/translation';
import { addBookingForMultipleUsers, AddMultipleBookingResponse } from '../services/bookingJob';
import { BookingEndsSelector } from './BookingEndsSelector';
import { formatPayLoadDate } from '../../../utils';
import { BookingStartsSelector } from './BookingsStartsSelector';
import { checkOverlap } from '../../../services/http';

interface BookingFormProps {
    onSubmit: (bookingForm: SingleBookingPayload) => Promise<HttpResponse<void>>;
    goBack: () => void;
    permit: Permit;
    initialValues: CreateOrEditBookingForm;
    onDurationPresetEdit: (durations: number[]) => void;
    bookingsAvailability: BookingAvailabilityState;
    fetchAvailability: (date: string, clientId: string) => void;
}

const validationSchema = Yup.object().shape({
    licensePlateNumber: Yup.string().required('Please enter a license plate.')
        .test(
            'max1000',
            'Please create less than 10 000 bookings at once.',
            ((value) => value && value.length > 0 && value.split(new RegExp('[,|;]')).length < 10000),
        ),
    type: Yup.string().required('Please select a booking type.'),
    validFrom: Yup.date(),
    validTo: Yup.date().required('Please select an end time for the booking.'),
    endType: Yup.mixed().required('Please select an end type for the booking.'),
});

let bookingFormikHelper: FormikHelpers<CreateOrEditBookingForm>;
let bookingForm: CreateOrEditBookingForm;

function BookingForm(props: BookingFormProps) {
    const { addToast } = useToasts();
    const isEdit = props.initialValues.bookingId;
    const [showOverlapConfirmation, setShowOverlapConfirmation] = React.useState<boolean>(false);

    function confirmMiddleware(form: CreateOrEditBookingForm, formikHelper: FormikHelpers<CreateOrEditBookingForm>) {
        if (props.initialValues.bookingId) {
            handleSubmit(form, formikHelper);
            return;
        }
        bookingFormikHelper = formikHelper;
        bookingForm = form;
        const isFixed = form.type === 'FIXED';
        const plates = form.licensePlateNumber.split(new RegExp('[,|;]')).map((plate) => {
            return plate.trim();
        }).filter((notEmptyPlate) => notEmptyPlate !== '');
        const payload: BookingPayload = {
            clientId: form.clientId,
            comment: form.comment,
            type: isFixed ? 'FIXED' : 'ENTRY',
            permitDefinitionId: props.permit.id,
            tenantId: props.permit.tenantId,
            operator: props.permit.operator,
            ...(!isFixed && { duration: form.duration }),
            ...(isFixed && { validTo: !!form.validTo ? formatPayLoadDate(form.validTo) : undefined }),
            ...(isFixed && { validFrom: !!form.validFrom ? formatPayLoadDate(form.validFrom) : undefined }),
            ...(form.bookingId && { bookingId: form.bookingId }),
        };

        const bookingArr: any[] = [];
        plates.forEach((plate) => {
            const booking = { licensePlateNumber: plate, ...payload };
            bookingArr.push(checkOverlap(booking));
        });
        Promise.all(bookingArr).then((responses: Array<HttpResponse<BookingOverlap>>) => {
            if (responses.some((response) => ['ERROR', 'ERROR_MESSAGE'].includes(response.type))) {
                addToast({
                    type: 'error',
                    body: Translation.messages().common.error.something_went_wrong,
                    autoDismiss: false,
                });
            } else {
                const overlaps = responses.filter((response) => response.type === 'SUCCESS' && response.data.result);
                if (overlaps.length > 0) {
                    setShowOverlapConfirmation(true);
                } else {
                    handleSubmit(form, formikHelper);
                }
            }
        });
    }

    function handleSubmit(form: CreateOrEditBookingForm, formikHelper: FormikHelpers<CreateOrEditBookingForm>) {
        const isFixed = form.type === 'FIXED';
        const plates = form.licensePlateNumber.split(new RegExp('[,|;]')).map((plate) => {
            return plate.trim();
        }).filter((notEmptyPlate) => notEmptyPlate !== '');
        const payload: BookingPayload = {
            clientId: form.clientId,
            comment: form.comment,
            type: isFixed ? 'FIXED' : 'ENTRY',
            permitDefinitionId: props.permit.id,
            tenantId: props.permit.tenantId,
            operator: props.permit.operator,
            ...(!isFixed && form.duration !== 0 && { duration: form.duration }),
            ...(!isFixed && (form.duration === 0 || form.duration === undefined) && { validTo: !!form.validTo ? formatPayLoadDate(form.validTo) : undefined }),
            ...(isFixed && { validTo: !!form.validTo ? formatPayLoadDate(form.validTo) : undefined }),
            ...(isFixed && { validFrom: !!form.validFrom ? formatPayLoadDate(form.validFrom) : undefined }),
            ...(form.bookingId && { bookingId: form.bookingId }),
        };

        if (plates.length > 1) {
            const multiplePayload: MultipleBookingPayload = { licensePlateNumbers: plates, ...payload };
            return addBookingForMultipleUsers(multiplePayload).then((res: AddMultipleBookingResponse) => {
                if (res.type === 'SUCCESS_ADDING_BOOKING') {
                    addToast({
                        type: 'success',
                        body: Translation.tokenize(Translation.messages().bookingClient.message.issuing_multiple_bookings)
                            .setValue('amount', plates.length.toString())
                            .buildString(),
                    });
                    props.goBack();
                } else {
                    addToast({
                        type: 'error',
                        body: 'Our server encountered an error and we were not able to create your bookings. Please try again later.',
                        autoDismiss: false,
                    });
                    formikHelper.setSubmitting(false);
                }
            });
        } else {
            const singlePayload = { licensePlateNumber: plates[0], ...payload };
            props.onSubmit(singlePayload).then((response: HttpResponse<void>) => {
                if (response.type === 'SUCCESS' || response.type === 'OK') {
                    const action = isEdit ? 'edited' : 'created';
                    addToast({ type: 'success', body: `Booking successfully ${action}` });
                    props.goBack();
                } else if (response.type === 'ERROR_MESSAGE') {
                    addToast({ type: 'error', body: response.message });
                } else {
                    const action = isEdit ? 'edit' : 'create';
                    addToast({ type: 'error', body: `Failed to ${action} the booking` });
                }

                formikHelper.setSubmitting(false);
            });
        }
    }

    const isSubmitDisabled = (isSubmitting: boolean) => {
        if (props.permit.accessLimit) {
            return isEdit ? isSubmitting : isSubmitting || props.bookingsAvailability === 'ERROR' || !props.bookingsAvailability?.available;

        }
        return isSubmitting;
    };

    return (
        <div className="booking-form">
            <Button
                color={'link-back'}
                tag={'button'}
                onClick={() => props.goBack()}
            >
                Go back
            </Button>
            <Spacer size="xs" />
            <h1>{isEdit ? 'Edit' : 'Create'} booking</h1>
            <Spacer size="sm" />
            <Formik
                validationSchema={validationSchema}
                initialValues={props.initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={confirmMiddleware}
            >
                {(formikProps) => (
                    <FormikForm>
                        <div className="permit-info-container">
                            <p><b>Permit: </b>{`${props.permit.name} (${props.permit.tenantName})`}</p>
                            {!isEdit && props.permit.accessLimit &&
                                (
                                    <>
                                        <Spacer size="xs" />
                                        <Availability bookingsAvailability={props.bookingsAvailability} showRefresh={false} />
                                    </>
                                )
                            }
                        </div>
                        <Spacer size="xs" />
                        <FormGroup>
                            <div className="label-with-tooltip">
                                <Label htmlFor="licensePlateNumber">License plate</Label>
                                <IconWithTooltip
                                    icon="tooltip"
                                    text={'You can create bookings for multiple license plates at the same time. License plates have to seperated by a comma or semicolon ";", i.e. KJ120120, TB140320'}
                                    position="right"
                                />
                            </div>
                            <Textarea
                                id="licensePlateNumber"
                                value={formikProps.values.licensePlateNumber}
                                onChange={(event) => formikProps.setFieldValue('licensePlateNumber', event.target.value)}
                                className="license-plate-number-text-area"
                            />
                            <FormikErrorMessage name="licensePlateNumber" />
                        </FormGroup>
                        <Spacer size="xs" />
                        <FormGroup>
                            <Label htmlFor="comment">Comment</Label>
                            <FormikInput
                                id="comment"
                                name="comment"
                                helperText="Do not share any sensitive information."
                            />
                        </FormGroup>
                        <Spacer size="sm" />
                        <BookingStartsSelector
                            permit={props.permit}
                            bookingAvailability={props.bookingsAvailability}
                            onDurationPresetEdit={props.onDurationPresetEdit}
                            fetchAvailability={props.fetchAvailability}
                        />
                        <Spacer size="sm" />
                        <BookingEndsSelector
                            permit={props.permit}
                            onDurationPresetEdit={props.onDurationPresetEdit}
                            availability={props.bookingsAvailability}
                            fetchAvailability={props.fetchAvailability}
                        />
                        <Spacer size="sm" />
                        <ButtonContainer alignment="left">
                            <FormikButton
                                type="submit"
                                loading={formikProps.isSubmitting}
                                disabled={isSubmitDisabled(formikProps.isSubmitting)}
                            >
                                {props.initialValues.bookingId ? 'Update booking' : 'Create booking'}
                            </FormikButton>
                            <Button type="reset" color="secondary" onClick={props.goBack} disabled={formikProps.isSubmitting}>
                                Cancel
                            </Button>
                        </ButtonContainer>
                        <Spacer size="lg" />
                    </FormikForm>
                )}
            </Formik>
            <Popup
                body={
                    (
                        <>
                            <p>An identical booking for the license plate: ***{bookingForm?.licensePlateNumber}*** already exists in the system for the assigned timeslot.</p>
                            <Spacer size="xxs" />
                            <b>Are you sure you want to duplicate this booking?</b>
                        </>
                    )
                }
                buttons={[
                    <Button key="btn-close" color="secondary" onClick={() => props.goBack()} tag="button">Cancel</Button>,
                    <Button key="btn-accept" color="primary" onClick={() => handleSubmit(bookingForm, bookingFormikHelper)}>Yes</Button>,
                ]}
                close={() => setShowOverlapConfirmation(false)}
                show={showOverlapConfirmation}
                title="Double booking?"
            />
        </div>
    );
}

export default BookingForm;
